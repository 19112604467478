import React from "react";

import PageWrapper from "../../components/PageWrapper";
import Hero from "../../sections/crews/Hero";
import Content1 from "../../sections/crews/Content1";
import Content2 from "../../sections/crews/Content2";
import Content3 from "../../sections/crews/Content3";

const Crews = () => {
  return (
    <>
      <PageWrapper>
        <Content1 className="pt-24 pt-lg-34 pb-10 pb-sm-5 pb-lg-14" />
        <Hero className="pt-11 pb-8 pb-lg-33" />
        <Content2 className="pb-0 pb-lg-31" />
        <Content3 className="pb-13 pb-lg-28" />
      </PageWrapper>
    </>
  );
};
export default Crews;
